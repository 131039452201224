@charset "UTF-8";
@use "../utility/variable" as *;

.header {
    // position: fixed;
}

// mv
.mv {
    background: linear-gradient(transparent 0% 55%, $baseColor 55% 100%);
    height: 100vh;
    display: flex;
    flex-direction: column;

    &__content {
        // padding-top: rem(170);
        padding-top: rem(80);
        padding-bottom: rem(35);
        width: 100%;
        margin: 0 auto;

        @include mq {
            // padding-top: rem(130);
            padding-top: rem(80);
            padding-left: rem(30);
        }
    }

    &__catch1 {
        font-size: rem(49);
        font-family: $second-font-family;
        letter-spacing: 0.14em;
        font-weight: $regular;
        margin-bottom: rem(5);

        @include mq {
            line-height: calc(38 / 30);
            font-size: rem(30);
            margin-bottom: rem(10);
        }
    }

    &__catch2 {
        font-size: rem(14);
        font-weight: $light;
        letter-spacing: 0.08em;
        font-family: $fourth-font-family;

        @include mq {
            font-size: rem(10);
        }
    }

    &__catch2 span {
        color: $red;
    }

    &__wrap {
        height: calc(100% - rem(310));
        position: relative;
        // margin-right: auto;
        aspect-ratio: 1220/520;
        max-width: 95%;

        @include mq {
            height: calc(100% - rem(260));
            aspect-ratio: 345/380;
        }
    }

    &__video {
        height: 100%;
        width: 100%;
    }

    &__video img {
        object-fit: cover;
    }

    &__video video {
        object-fit: cover;
        height: 100%;
        width: 100%;
        display: block;
    }

    &__arrow {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(40%, -50%);
        aspect-ratio: 1;
        height: rem(87);
        border-radius: 100%;
        background: $baseColor;

        @include mq {
            transform: translate(25%, -50%);
            height: rem(60);
        }
    }

    &__arrow-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        aspect-ratio: 12/23;
        width: rem(12);
        overflow: hidden;

        @include mq {
            width: rem(8);
        }
    }

    &__arrow-img img {
        animation: mv-arrow 2.3s ease-in-out infinite;
    }
}

@keyframes mv-arrow {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    35% {
        transform: translateY(100%);
        opacity: 0;
    }

    36% {
        transform: translateY(-100%);
        opacity: 0;
    }

    65% {
        transform: translateY(0);
        opacity: 1;
    }
}

// /mv

// campaign
.campaign {
    margin-top: -1px;
    background: $baseColor;
    padding-top: rem(55);
    padding-bottom: rem(80);

    @include mq {
        padding: rem(35) rem(17) rem(55);
    }

    &__content {
        display: block;
        max-width: calc(rem(780) + ($padding-pc * 2));
        margin: auto;
        padding: rem(20);
        background: $white;
        transition: 0.3s all ease-in-out;

        @include mq {
            max-width: rem(600);
            padding: rem(20) rem(12) rem(15);
        }
    }

    &__content:hover {
        opacity: 0.8;
    }

    &__top {
        display: flex;
        align-items: center;
        gap: rem(10);
        padding-bottom: rem(10);
        border-bottom: 1px solid rgba($color: $black2, $alpha: 0.6);
        margin-bottom: rem(20);

        @include mq {
            display: block;
            padding-bottom: rem(5);
            margin-bottom: rem(10);
        }
    }

    &__subttl {
        font-weight: $semibold;
        color: $red;
        line-height: 1;

        @include mq {
            text-align: center;
            margin-bottom: rem(5);
        }
    }

    &__ttl {
        font-size: rem(26);
        letter-spacing: 0.08em;
        color: $baseColor;
        font-family: $second-font-family;

        @include mq {
            font-size: rem(18);
            letter-spacing: 0;
            text-align: center;
        }
    }

    &__body {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mq {
            display: block;
            position: relative;
        }
    }

    &__left {
        width: calc(100% - rem(100));

        @include mq {
            width: 100%;
        }
    }

    &__des {
        margin-bottom: rem(5);
        color: $baseColor;

        @include mq {
            margin-bottom: rem(15);
            font-feature-settings: "palt";
            letter-spacing: 0;
        }
    }

    &__time {
        font-family: $fourth-font-family;
        background: $baseColor;
        padding: rem(5) rem(10);
        color: $white;
        width: fit-content;

        @include mq {
            line-height: 1;
        }
    }

    &__arrow {
        background: $red;

        @include mq {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
}

// /campaign

// life
.life {
    // padding-top: rem(70);
    // @include mq{
    //     padding-top: rem(40);
    // }
    margin-top: -1px;
    background: $baseColor;

    &__container {
        padding-bottom: rem(60);

        @include mq {
            padding-bottom: rem(30);
        }
    }

    &__inner {
        max-width: calc(rem(1220) + ($padding-pc * 2));
        margin: auto;
        padding: 0 $padding-pc;

        @include mq {
            max-width: rem(600);
            padding: 0 $padding-sp;
        }
    }

    &__ttl {
        margin-bottom: rem(30);
        text-align: right;
        color: $white;

        @include mq {
            text-align: center;
        }
    }

    &__contents {
        background: $white;
        padding: 1px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1px;

        @include mq {
            grid-template-columns: 1fr;
        }
    }
}

.life-c {
    background-repeat: no-repeat;
    background-size: cover;
    padding: rem(55) rem(40) rem(35);
    position: relative;

    @include mq {
        padding: rem(20);
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: $black;
        opacity: 0.6;
        z-index: 1;
        transition: 0.3s all ease-in-out;
    }

    &:hover::after {
        opacity: 0.8;
    }

    &__logo {
        position: absolute;
        z-index: 2;
        bottom: rem(35);
        left: rem(40);
        height: auto;
        width: auto;

        @include mq {
            left: rem(20);
            bottom: rem(20);
        }
    }

    &__ttl {
        position: relative;
        z-index: 2;
        font-size: rem(28);
        font-family: $second-font-family;
        letter-spacing: 0.08em;
        line-height: calc(37 / 28);
        color: $white;
        margin-bottom: rem(65);

        @include mq {
            margin-bottom: rem(40);
            font-size: rem(18);
        }
    }

    &__more {
        position: relative;
        z-index: 2;
        width: fit-content;
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: rem(15);

        @include mq {
            gap: rem(10);
        }
    }

    &__more-text {
        font-size: rem(18);
        font-family: $fourth-font-family;
        font-weight: $light;
        letter-spacing: 0.06em;
        color: $white;

        @include mq {
            font-size: rem(12);
        }
    }

    &__arrow {
        background: $white;
    }

    &__arrow::after {
        filter: brightness(0);
    }
}

.life-c--guard {
    background-image: url("../../img/top/life1.jpg");

    .life-c__logo {
        width: rem(76);
        aspect-ratio: 76/87;

        @include mq {
            speak-as: 44/50;
            width: rem(44);
        }
    }
}

.life-c--feynlab {
    background-image: url("../../img/top/life2.jpg");

    .life-c__logo {
        width: rem(182);
        aspect-ratio: 182/45;

        @include mq {
            aspect-ratio: 105/26;
            width: rem(105);
        }
    }
}

.life-c--ceramic {
    background-image: url("../../img/top/life3.jpg");

    .life-c__logo {
        width: rem(180);
        aspect-ratio: 180/44;

        @include mq {
            width: rem(105);
        }
    }
}

.life-c--eterno {
    background-image: url("../../img/top/life4.jpg");

    .life-c__logo {
        width: rem(76);
        aspect-ratio: 76/87;
    }
}

// /life

// skill
.skill {
    &__container {
        padding: rem(80) 0 0;

        @include mq {
            padding-top: rem(50);
        }
    }

    &__top {
        margin-bottom: rem(30);

        @include mq {
            margin-bottom: rem(20);
        }
    }

    &__subttl {
        margin-bottom: rem(5);
        color: $red;
    }

    &__ttl {
        @include mq {
            font-feature-settings: "palt";
        }
    }

    &__contents {
        background: rgba($color: $black2, $alpha: 0.6);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1px;
        padding: 1px 0;

        @include mq {
            grid-template-columns: 1fr;
        }
    }
}

.skill-c {
    padding: rem(50) rem(25) rem(35);
    background: $white;
    display: flex;
    flex-direction: column;

    @include mq {
        padding: rem(35) rem(40);
    }

    &__img {
        height: rem(80);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: rem(20);

        @include mq {
            height: rem(85);
        }
    }

    &__img picture {
        height: auto;
    }

    &__body {
        display: flex;
        flex-direction: column;
    }

    &__ttl-wrap {
        flex-grow: 1;
        flex-basis: rem(65);
        display: flex;
        align-items: center;
        text-align: center;
        margin: auto;
        margin-bottom: rem(15);

        @include mq {
            flex-basis: unset;
            flex-grow: unset;
        }
    }

    &__ttl {
        font-size: rem(20);
        font-weight: $bold;
        letter-spacing: 0.08em;
        line-height: calc(28 / 20);
        text-align: center;

        @include mq(tb) {
            font-size: rem(15);

            @include mq {
                font-size: rem(18);
                width: 100%;
            }
        }
    }
}

.skill-c:nth-of-type(1) {
    .skill-c__img picture {
        aspect-ratio: 85/80;
        width: rem(85);
    }
}

.skill-c:nth-of-type(2) {
    .skill-c__img picture {
        aspect-ratio: 109/53;
        width: rem(109);
    }
}

.skill-c:nth-of-type(3) {
    .skill-c__img picture {
        aspect-ratio: 97/63;
        width: rem(97);
    }
}

.skill-c:nth-of-type(4) {
    .skill-c__img picture {
        aspect-ratio: 69/80;
        width: rem(69);
    }
}

//  /skill

// obsession
.obsession {
    &__inner {
        @include mq {
            max-width: unset;
            padding: unset;
        }
    }

    &__top {
        padding: rem(185) rem(25);
        background-image: url("../../img/top/obsession-top.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        @include mq {
            padding: rem(130) rem(5) rem(140);
        }
    }

    &__top::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.6);
        z-index: 1;
    }

    &__ttl {
        font-size: rem(49);
        font-family: $second-font-family;
        color: $white;
        letter-spacing: 0.14em;
        line-height: calc(64 / 49);
        text-align: center;
        margin-bottom: rem(20);
        position: relative;
        z-index: 2;

        @include mq {
            font-size: rem(28);
            margin-bottom: rem(17);
            font-feature-settings: "palt";
        }
    }

    &__subttl {
        width: fit-content;
        font-size: rem(20);
        font-weight: $normal;
        letter-spacing: 0.08em;
        line-height: 1;
        color: $white;
        position: relative;
        margin: auto;
        padding: 0 rem(20);
        position: relative;
        z-index: 2;

        @include mq {
            font-size: rem(13);
            padding: 0 rem(5);
        }
    }

    &__subttl::after,
    &__subttl::before {
        position: absolute;
        content: "";
        display: block;
        width: rem(115);
        height: 1px;
        background: $white;
        z-index: 2;

        @include mq {
            width: rem(55);
        }
    }

    &__subttl::after {
        top: 50%;
        transform: translateY(-50%);
        left: 100%;

        @include mq {
            transform: unset;
        }
    }

    &__subttl::before {
        top: 50%;
        transform: translateY(-50%);
        right: 100%;

        @include mq {
            transform: unset;
        }
    }

    &__contents {
        margin-top: rem(-30);

        @include mq {
            margin-top: unset;
            padding-top: rem(35);
        }
    }

    &__content {
        margin-bottom: rem(60);

        @include mq {
            margin-bottom: rem(65);
        }
    }

    &__bottom {
        margin-bottom: rem(60);

        @include mq {
            margin-bottom: rem(40);
        }
    }

    &__images {
        margin-bottom: rem(60);

        @include mq {
            margin-bottom: rem(40);
        }
    }

    &__content {
        margin-bottom: rem(55);

        @include mq {
            margin-bottom: rem(30);
        }
    }

    &__content:last-of-type {
        @include mq {
            margin-bottom: rem(40);
        }
    }

    &__bottom-wrap {
        @include mq {
            padding: 0 $padding-sp;
            margin: auto;
            max-width: rem(600);
        }
    }
}

.obsession-c {
    position: relative;
    display: flex;
    gap: rem(40);
    align-items: flex-end;

    @include mq {
        gap: rem(25);
    }

    &__img {
        aspect-ratio: 615/380;
        width: 55%;

        @include mq {
            width: calc(100% - rem(27));
        }
    }

    &__side {
        position: absolute;
        writing-mode: vertical-rl;
        top: 25%;
        font-size: rem(14);
        font-weight: $light;
        letter-spacing: 0.08em;
        color: $black2;

        @include mq {
            top: 0;
            font-size: rem(11);
        }
    }

    &__body {
        width: calc(45% - rem(40));

        @include mq {
            width: 100%;
            padding-top: rem(50);
            max-width: rem(600);
            padding: rem(35) rem(27) 0;
            margin: auto;
        }
    }

    &__ttl {
        font-size: rem(28);
        line-height: calc(37 / 28);
        font-family: $second-font-family;
        margin-bottom: rem(15);
        letter-spacing: 0.08em;

        @include mq {
            font-size: rem(20);
            margin-bottom: rem(20);
        }
    }

    &__des {
        margin-bottom: rem(35);

        @include mq {
            margin-bottom: unset;
            letter-spacing: 0.02em;
        }
    }
}

.obsession-c:nth-of-type(2) {
    .obsession-c__side {
        top: 31%;

        @include mq {
            top: 0;
        }
    }
}

.obsession-c:nth-of-type(3) {
    .obsession-c__side {
        top: 35%;

        @include mq {
            top: 0;
        }
    }
}

.obsession-c:nth-of-type(2n) {
    flex-direction: row;

    @include mq {
        flex-direction: column-reverse;
    }

    .obsession-c__body {
        padding-right: rem(80);

        @include mq {
            padding-right: rem(55);
        }
    }

    .obsession-c__img {
        @include mq {
            margin-right: auto;
        }
    }

    .obsession-c__side {
        right: rem(25);

        @include mq {
            right: rem(15);
        }
    }
}

.obsession-c:nth-of-type(2n-1) {
    flex-direction: row-reverse;

    @include mq {
        flex-direction: column-reverse;
    }

    .obsession-c__body {
        padding-left: rem(80);

        @include mq {
            padding-left: rem(55);
        }
    }

    .obsession-c__side {
        left: rem(25);

        @include mq {
            left: rem(15);
        }
    }
}

.obsession-b {
    display: block;
    padding: rem(60) rem(20);
    border: 1px solid rgba($color: $black2, $alpha: 0.6);

    @include mq {
        padding: rem(30) rem(15);
    }

    &__more {
        display: flex;
        justify-content: center;
        gap: rem(18);
        align-items: center;

        @include mq {
            gap: rem(12);
        }
    }

    &__text {
        font-size: rem(20);
        letter-spacing: 0.08em;
        font-weight: $normal;
        line-height: 1;
        color: $baseColor;

        @include mq {
            font-size: rem(15);
        }
    }

    &__arrow {
        background: $red;
    }
}

.obsession-i {
    &__wrap {
        transition-timing-function: linear;
    }

    &__image {
        aspect-ratio: 432/267;
    }
}

// /obsession

// menu
.menu {
    background: $baseColor;
    overflow: hidden;

    &__container {
        padding: rem(90) 0 0;

        @include mq {
            padding: rem(55) 0;
        }
    }

    &__top {
        margin-bottom: rem(40);
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        @include mq {
            flex-direction: column;
            gap: rem(20);
            align-items: unset;
            margin-bottom: rem(20);
        }
    }

    &__subttl {
        color: $red;
        margin-bottom: rem(5);
    }

    &__ttl {
        color: $white;
    }

    &__top-arrows {
        display: flex;
        gap: rem(15);

        @include mq {
            gap: rem(10);
        }
    }

    & .arrow {
        border: 1px solid $white;
        transition: 0.3s all ease-in-out;

        @include mq {
            height: rem(22);
        }
    }

    & .arrow:hover {
        border: 1px solid $red;
    }

    &__top-prev {
        transform: rotate(180deg);
    }

    &__wrap {
        margin-bottom: rem(60);

        @include mq {
            margin-bottom: rem(40);
        }
    }

    &__bottom {
        margin-bottom: rem(80);

        @include mq {
            margin-bottom: unset;
        }
    }
}

.menu-c {
    width: rem(519);
    height: auto;
    border-radius: rem(15);
    background: $white;
    overflow: hidden;

    // display: flex;
    // flex-direction: column;
    @include mq {
        width: unset;
    }

    &__ttl {
        text-align: center;
        padding: rem(20) rem(15);
        background: $red;
        font-size: rem(24);
        font-family: $second-font-family;
        letter-spacing: 0.08em;
        line-height: calc(34 / 24);
        color: $white;
        margin-bottom: rem(30);

        @include mq(tb) {
            font-size: rem(20);

            @include mq {
                font-size: rem(16);
                padding: rem(10) rem(8);
                margin-bottom: rem(15);
            }
        }
    }

    &__body {
        // flex-grow: 1;
        padding: 0 rem(20) rem(30);

        @include mq {
            padding: rem(0) rem(10) rem(20);
        }
    }

    &__name {
        font-size: rem(25);
        color: $baseColor;
        font-weight: $normal;
        letter-spacing: 0.06em;
        line-height: calc(34 / 25);
        margin-bottom: rem(8);
        text-align: center;

        @include mq {
            font-size: rem(16);
        }
    }

    &__price {
        font-size: rem(68);
        line-height: 1;
        font-family: $second-font-family;
        color: $red;
        margin-bottom: rem(25);
        text-align: center;

        @include mq {
            font-size: rem(38);
            margin-bottom: rem(10);
        }
    }

    &__price span {
        font-family: $second-font-family;
        font-size: rem(54);
        margin-right: rem(3);

        @include mq {
            font-size: rem(30);
        }
    }

    &__lists {
        width: fit-content;
        margin: auto;
    }

    &__list {
        padding-left: rem(30);
        position: relative;
        font-size: rem(20);
        color: $baseColor;
        font-weight: $normal;
        letter-spacing: 0.06em;
        line-height: calc(34 / 20);

        @include mq {
            font-size: rem(12);
            padding-left: rem(16);
        }
    }

    &__list::before {
        content: "";
        display: block;
        position: absolute;
        height: rem(18);
        width: rem(9);
        border-right: 1px solid $red;
        border-bottom: 1px solid $red;
        top: rem(4);
        left: 0;
        transform: rotate(45deg);

        @include mq {
            height: rem(10);
            width: rem(5);
            top: rem(3);
        }
    }
}

.menu-b {
    padding: rem(60);
    display: flex;
    border: 1px solid $white;
    align-items: center;
    justify-content: space-between;

    @include mq {
        padding: rem(25) rem(30) rem(35);
        display: block;
    }

    &__des {
        font-size: rem(20);
        font-weight: $normal;
        letter-spacing: 0.08em;
        line-height: 1.2;
        color: $white;

        @include mq {
            text-align: center;
            margin-bottom: rem(30);
            font-size: rem(13);
            line-height: 1.5;
        }
    }

    &__more {
        display: flex;
        align-items: center;
        gap: rem(15);

        @include mq {
            justify-content: center;
        }
    }

    &__more-text {
        font-size: rem(20);
        font-weight: $normal;
        letter-spacing: 0.08em;
        line-height: 1;
        color: $white;

        @include mq {
            font-size: rem(15);
        }
    }

    &__arrow {
        background: $white;
    }

    &__arrow::after {
        filter: brightness(0);
    }
}

// /menu

// works

.works {
    margin-top: -1px;
    background: linear-gradient($baseColor 0% 35%, transparent 35% 100%);

    @include mq {
        background: linear-gradient($baseColor 0% 13%, transparent 13% 100%);
    }

    &__container {
        padding-bottom: rem(60);

        @include mq {
            padding-bottom: rem(40);
        }
    }

    &__top {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: rem(40);

        @include mq {
            margin-bottom: rem(30);
        }
    }

    &__subttl {
        color: $red;
        margin-bottom: rem(5);
    }

    &__ttl {
        color: $white;
    }

    &__more {
        margin-bottom: rem(5);
        display: flex;
        gap: rem(15);
        align-items: center;

        @include mq {
            padding: rem(35) rem(15);
            border: 1px solid rgba($color: $black2, $alpha: 0.6);
            justify-content: center;
        }
    }

    &__more-text {
        color: $white;
        font-size: rem(16);
        font-weight: $normal;
        letter-spacing: 0.08em;
        line-height: 1;

        @include mq {
            color: $baseColor;
            font-size: rem(15);
        }
    }

    &__arrow {
        background: $white;

        @include mq {
            background: $baseColor;
            border: 1px solid $baseColor;
        }
    }

    &__arrow::after {
        filter: brightness(0);

        @include mq {
            filter: unset;
        }
    }

    &__contents {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: rem(30);

        @include mq {
            grid-template-columns: 1fr 1fr;
            gap: rem(30) rem(12);
            margin-bottom: rem(35);
        }
    }
}

.works-c {
    display: flex;
    flex-direction: column;

    &__img {
        // aspect-ratio: 260/195;
        position: relative;
        margin-bottom: rem(15);

        @include mq {
            margin-bottom: rem(10);
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__img::before {
        content: "";
        display: block;
        padding-top: 75%;
    }

    &__body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__cats {
        display: flex;
        flex-wrap: wrap;
        gap: rem(3);
        margin-bottom: rem(10);

        @include mq {
            margin-bottom: rem(7);
        }
    }

    &__cat p {
        padding: rem(6);
        color: $white;
        font-size: rem(12);
        font-weight: normal;
        letter-spacing: 0.06em;
        line-height: 1;
        width: fit-content;
        background: $baseColor;

        @include mq {
            font-size: rem(10);
            padding: rem(4) rem(6);
        }
    }

    &__name {
        color: $baseColor;
        font-size: rem(16);
        font-family: $second-font-family;
        letter-spacing: 0.08em;
        line-height: calc(24 / 16);
        padding-bottom: rem(5);
        border-bottom: 1px solid rgba($color: $black2, $alpha: 0.6);
        margin-bottom: rem(15);
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        @include mq {
            font-size: rem(13);
            margin-bottom: rem(12);
            letter-spacing: 0.08em;
        }
    }

    &__des {
        color: $baseColor;
        font-size: rem(12);
        flex-grow: 1;
        margin-bottom: rem(10);
        letter-spacing: 0;

        @include mq {
            font-size: rem(11);
        }
    }

    &__date {
        color: $baseColor;
        font-size: rem(10);
        font-weight: $light;
        letter-spacing: 0.06em;
        font-family: $fourth-font-family;
        color: $black2;
    }
}

// /works

// pickup
.pickup {
    background: $red;
    overflow: hidden;

    &__container {
        padding: rem(85) 0 rem(55);

        @include mq {
            padding: rem(55) 0 rem(35);
        }
    }

    &__top__box {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: rem(30);
        @include mq {
            margin-bottom: rem(25);
        }
    }

    &__top {
        // margin-bottom: rem(30);

        // @include mq {
        //     margin-bottom: rem(25);
        // }
    }

    &__subttl {
        color: $white;
        margin-bottom: rem(5);
    }

    &__ttl {
        color: $white;
    }

    &__contents {
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(20);
    }
}

.pickup-c {
    position: relative;
    padding: rem(35) 0 rem(40) rem(80);

    @include mq {
        padding: rem(20) 0 rem(30) rem(15);
    }

    &::after {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        width: 100vw;
        background: $white;
        z-index: 1;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: rem(30);
        position: relative;
        z-index: 2;

        @include mq {
            margin-bottom: rem(15);
        }
    }

    &__ttl {
        font-size: rem(28);
        font-family: $second-font-family;
        letter-spacing: 0.08em;
        color: $baseColor;
        position: relative;

        @include mq {
            font-size: rem(18);
            padding-left: rem(15);
        }
    }

    &__ttl::after {
        position: absolute;
        content: "";
        display: block;
        height: 1px;
        width: 15vw;
        top: 50%;
        right: calc(100% + rem(5));
        transform: translateY(-50%);
        background: $red;

        @include mq {
            right: 97%;
            top: rem(15);
        }
    }

    &__more {
        display: flex;
        gap: rem(15);
        align-items: center;
        @include mq {
            position: relative;
            z-index: 2;
            justify-content: flex-end;
        }
    }

    &__more-text {
        color: #fff;
        font-size: rem(16);
        font-weight: $normal;
        letter-spacing: 0.08em;
        line-height: 1;

        @include mq {
            font-size: rem(15);
        }
    }

    &__arrow {
        background: #fff;
    }

    &__arrow::after {
        filter: brightness(0);
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        gap: rem(10);
        position: relative;
        z-index: 2;

        @include mq {
            display: grid;
            grid-template-columns: 1fr;
            margin-bottom: rem(25);
        }
    }

    &__item {
        width: calc((100% / 3) - calc(rem(20) / 3));
        position: relative;
        text-align: center;
        padding: rem(40) rem(15) rem(40);
        background-repeat: repeat;
        background-size: cover;

        @include mq {
            width: 100%;
            padding: rem(50) rem(15) rem(45);
        }
    }

    &__item::after {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba($color: $baseColor, $alpha: 0.6);
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }

    &__item--half {
        width: calc(50% - rem(5));

        @include mq {
            width: 100%;
        }
    }

    &__part {
        color: $white;
        font-size: rem(20);
        font-weight: $normal;
        letter-spacing: 0.08em;
        position: relative;
        z-index: 2;

        @include mq {
            font-size: rem(15);
        }
    }
}

.pickup-c--ex {
    .pickup-c__item--wh {
        background-image: url("../../img/top/pickup1.jpg");
    }

    .pickup-c__item--pl {
        background-image: url("../../img/top/pickup2.jpg");
    }

    .pickup-c__item--wi {
        background-image: url("../../img/top/pickup3.jpg");
    }

    .pickup-c__item--hl {
        background-image: url("../../img/top/pickup4.jpg");
    }

    .pickup-c__item--mm {
        background-image: url("../../img/top/pickup5.jpg");
    }

    .pickup-c__item--hr {
        background-image: url("../../img/top/pickup6.jpg");
    }
}

.pickup-c--in {
    .pickup-c__item--le {
        background-image: url("../../img/top/pickup7.jpg");
    }

    .pickup-c__item--pl {
        background-image: url("../../img/top/pickup8.jpg");
    }
}

// /pickup

// maintain
.maintain {
    overflow: hidden;
    background: $white;
    margin-bottom: rem(80);

    @include mq {
        margin-bottom: rem(60);
    }

    &__container {
        padding-top: rem(85);
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include mq {
            padding-top: rem(55);
            display: block;
        }
    }

    &__texts {
        width: 46%;
        padding-right: rem(50);

        @include mq {
            width: 100%;
            padding-right: unset;
        }
    }

    &__img {
        width: 54%;

        @include mq {
            aspect-ratio: 375/230;
            width: 100vw;
            margin: 0 calc(50% - 50vw);
        }
    }

    &__img picture img {
        width: 54vw;
        aspect-ratio: 705/435;
        max-width: unset;

        @include mq {
            aspect-ratio: unset;
            width: unset;
            max-width: 100%;
        }
    }

    &__subttl {
        margin-bottom: rem(5);
        color: $red;
    }

    &__ttl {
        color: $baseColor;
        margin-bottom: rem(10);

        @include mq {
            margin-bottom: rem(15);
        }
    }

    &__des {
        margin-bottom: rem(50);

        @include mq {
            margin-bottom: rem(30);
        }
    }

    &__more {
        display: flex;
        align-items: center;
        gap: rem(15);

        @include mq {
            padding: rem(35) 0;
            border: 1px solid rgba($color: $black2, $alpha: 0.6);
            justify-content: center;
            margin-bottom: rem(35);
        }
    }

    &__more-text {
        color: $baseColor;
        font-size: rem(20);
        font-weight: $normal;
        letter-spacing: 0.05em;
        line-height: 1;

        @include mq {
            font-size: rem(15);
            line-height: 1.4;
        }
    }

    &__arrow {
        background: $red;
    }
}

// /maintain

// news
.news {
    background: $white;

    &__container {
        padding-bottom: rem(120);

        @include mq {
            padding-bottom: rem(60);
        }
    }

    &__top {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: rem(30);

        @include mq {
            margin-bottom: rem(25);
        }
    }

    &__subttl {
        color: $red;
        margin-bottom: rem(5);
    }

    &__ttl {
        color: $baseColor;
    }

    &__more {
        display: flex;
        align-items: center;
        gap: rem(15);

        @include mq {
            gap: rem(10);
            justify-content: flex-end;
        }
    }

    &__more-text {
        font-size: rem(16);
        font-weight: $normal;
        letter-spacing: 0.08em;
        color: $baseColor;
        line-height: 1;

        @include mq {
            font-size: rem(15);
        }
    }

    &__arrow {
        background: $baseColor;
    }

    &__items {
        @include mq {
            margin-bottom: rem(30);
        }
    }
}

.news-i {
    border-bottom: 1px solid rgba($color: $black2, $alpha: 0.6);

    &__wrap {
        display: flex;
        align-items: flex-start;
        padding: rem(25);

        @include mq {
            flex-wrap: wrap;
            padding: rem(10) rem(10) rem(15);
            gap: rem(5);
        }
    }

    &__date {
        margin-top: rem(5);
        font-size: rem(14);
        font-family: $fourth-font-family;
        font-weight: $light;
        color: $black2;
        padding-right: rem(33);
        width: fit-content;

        @include mq {
            font-size: rem(10);
            padding-right: rem(12);
        }
    }

    &__cat {
        color: $white;
        padding: rem(5) rem(25);
        border-radius: rem(100);
        background: $gray2;

        @include mq {
            font-size: rem(10);
            padding: rem(5) rem(14);
        }
    }

    &__ttl {
        margin-top: rem(5);
        padding-left: rem(15);
        font-size: rem(16);
        font-family: $second-font-family;
        line-height: calc(24 / 16);
        letter-spacing: 0.08em;
        color: $baseColor;
        transition: 0.3s all ease-in-out;

        @include mq {
            width: 100%;
            padding-left: unset;
            font-size: rem(15);
            margin-top: unset;
        }
    }

    &:hover &__ttl {
        color: $red;
    }
}

.news-i:first-of-type {
    border-top: 1px solid rgba($color: $black2, $alpha: 0.6);
}

// /news
